import React, { useEffect, useRef } from 'react';
import design from 'assets/evilayet/evilayet1.jpg';
import design2 from 'assets/evilayet/evilayet2.jpg';
import ImageGallery from 'react-image-gallery';

// https://github.com/xiaolin/react-image-gallery?tab=readme-ov-file

const EvilayetUi = (props: {}) => {
  const galleryRef = useRef();

  // useEffect(() => {
  //   galleryRef?.current && galleryRef.current.toggleFullScreen();
  // }, []);

  const images = [design, design2].map(e => {
    return {
      original: e,
      thumbnail: e,
    };
  });

  return (
    <ImageGallery ref={galleryRef} items={images} lazyLoad={true} useBrowserFullscreen={false} />
  );
};

export default EvilayetUi;
