import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import React, { useState } from 'react';

interface Props {
  data: any | any[];
  customRenders?: any;
  isLoading?: boolean;
  idAccessor?: string;
  withTableBorder?: boolean;
  striped?: boolean;
  highlightOnHover?: boolean;
  noHeader?: boolean;
  horizontalSpacing?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  verticalSpacing?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const locale = 'tr-TR';

export const JsonToReactMantineDataTable = ({
  data,
  customRenders,
  withTableBorder,
  idAccessor,
  highlightOnHover,
  striped,
  noHeader,
  horizontalSpacing,
  verticalSpacing,
}: Props) => {
  const f = (param: string) => {
    return `${param.substring(0, 1).toLocaleUpperCase(locale)}${param.substring(1)}`;
  };
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: 'id',
    direction: 'asc',
  });

  const keys = Object.keys(data[0]);
  const cols = keys?.map((key: any) => {
    const obj = {};
    if (customRenders?.[key]?.render) obj.render = customRenders[key].render;

    return {
      accessor: key,
      title: f(key),
      sortable: false,
      ...obj,
    };
  });

  return (
    <>
      {data && (
        <div className="flex flex-col w-full h-[80%]">
          <DataTable
            noHeader={noHeader ?? false}
            striped
            highlightOnHover={highlightOnHover ?? true}
            records={data}
            idAccessor={idAccessor ?? 'id'}
            withTableBorder={withTableBorder ?? false}
            scrollAreaProps={{ type: 'never' }}
            horizontalSpacing={horizontalSpacing ?? 'xs'}
            verticalSpacing={verticalSpacing ?? 'xs'}
            shadow="md"
            //@ts-ignore
            columns={cols}
            noRecordsText="No records found"
            sortStatus={sortStatus}
            onSortStatusChange={setSortStatus}
          />
        </div>
      )}
    </>
  );
};
