import React, { useEffect, useState } from 'react';
import { DataTable } from 'mantine-datatable';
import { Tabs } from '@mantine/core';
import axios from 'axios';
import { useDisclosure } from '@mantine/hooks';
import './drive.css';

import { Button, Modal } from '@mantine/core';
import { FilePond } from 'react-filepond';
import { process, load, retry } from './driveFilepond';
import { useNavigate } from 'react-router-dom';

function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const DriveTable = ({ parentId, value }) => {
  const [isLoading, setLoading] = useState(true);
  const [files, setFiles] = useState();
  const [table, setTable] = useState([]);
  const history = useNavigate();

  const [opened, { open, close }] = useDisclosure(false);
  const loadTable = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`/drive/list/${parentId}`);
      if (data) {
        setTable(data);
        console.log(data);
      }
    } catch (e) { }

    setLoading(false);
  };

  useEffect(() => {
    loadTable();
  }, []);

  const server = {
    process: process(parentId, loadTable),
    load: load(parentId),
    retry: retry(parentId),
  };

  return (
    <Tabs.Panel value={value}>
      <div className="flex flex-col mt-4">
        <Modal
          opened={opened}
          onClose={() => {
            close();
            setFiles(undefined);
          }}
          title="Upload new file"
          centered
          size="60%"
          transitionProps={{ transition: 'fade', duration: 600, timingFunction: 'linear' }}
        >
          <div className="h-[30vh]">
            <FilePond
              files={files}
              onupdatefiles={setFiles}
              allowMultiple={true}
              allowReorder={true}
              maxFiles={5}
              // server={`${process.env.REACT_APP_ORTHORECTA_BFF_URL}/upload/${parentId}`}
              name="files" /* sets the file input name, it's filepond by default */
              labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
              server={server}
            />
          </div>
        </Modal>
        <div className="flex justify-start pb-3">
          <Button
            color="lime"
            onClick={() => {
              window.location.href = `https://drive.google.com/drive/u/0/folders/${parentId}`;
            }}
          >
            Go to drive
          </Button>
        </div>

        <div className="flex justify-end pb-3">
          <Button color="lime" onClick={open}>
            Upload new file
          </Button>
        </div>

        <DataTable
          striped
          highlightOnHover
          records={table}
          fetching={isLoading}
          idAccessor="id"
          scrollAreaProps={{ type: 'never' }}
          columns={[
            {
              accessor: 'name',
              title: 'Name',
            },
            {
              accessor: '_',
              title: 'Size',
              render: ({ size }) => {
                return formatBytes(size, 0);
              },
            },
          ]}
          // 👇 override default text color for light and dark themes
          // c={{ dark: '#dbc7a0', light: '#55350d' }}
          // 👇 override default background color for light and dark themes
          // backgroundColor={{ dark: '#232b25', light: '#f0f7f1' }}
          // borderColor="#40c057" // 👈 override default border color
          // rowBorderColor="#fab005" // 👈 override default row border color
          paginationActiveBackgroundColor="#40c057" // 👈 override default pagination active background color
          noRecordsText="No records found"
        />
      </div>
    </Tabs.Panel>
  );
};
