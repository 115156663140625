import React, { useEffect, useRef } from 'react';
import ImageGallery from 'react-image-gallery';

// https://github.com/xiaolin/react-image-gallery?tab=readme-ov-file

const ElecricityUi = (props: {}) => {
  const galleryRef = useRef();

  // useEffect(() => {
  //   galleryRef?.current && galleryRef.current.toggleFullScreen();
  // }, []);

  const images = ['https://pbs.twimg.com/media/GPI3RGnW0AA-s9p?format=png&name=4096x4096'].map(
    e => {
      return {
        original: e,
        thumbnail: e,
      };
    },
  );

  return (
    <ImageGallery ref={galleryRef} items={images} lazyLoad={true} useBrowserFullscreen={false} />
  );
};

export default ElecricityUi;
