import axios from 'axios';

const controller = new AbortController();

const process = (parentId: number, callback?: () => {}) => {
  return async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
    const formData = new FormData();
    formData.append(fieldName, file, file.name);

    try {
      const config = {
        onUploadProgress: (e: any) => {
          progress(e.lengthComputable, e.loaded, e.total);
        },
      };

      const url = `/upload/${parentId}`;
      const { data } = await axios.post(url, formData, {
        ...config,
        signal: controller.signal,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      load(data);
      callback && callback();
      console.log(file);
    } catch (e) {
      error(e);
    }

    return {
      abort: () => {
        controller.abort();
        abort();
      },
    };
  };
};

const load = (parentId: number, callback?: () => {}) => {
  return (source, load, error, progress, abort, headers) => {
    error('oh my goodness');
    console.log('here?');

    progress(true, 0, 1024);

    return {
      abort: () => {
        abort();
      },
    };
  };

  // load: (source, load) => {
  //   // simulates loading a file from the server
  //   fetch(source)
  //     .then(res => res.blob())
  //     .then(load);
  // },
};

const retry = parentId => {
  return (uniqueFileId, load, error) => {
    // Should remove the earlier created temp file here
    // ...

    // Can call the error method if something is wrong, should exit after
    error('oh my goodness');

    // Should call the load method when done, no parameters required
    load();
  };
};

export { process, load, retry };
