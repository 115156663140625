import DriveContainer from 'drive/DriveContainer';
import { Home } from 'home/Home';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AuthLayout from 'route/AuthLayout';
import Cluster from 'cluster/Cluster';
import { UiHome } from 'ui/UiHome';
import { Templates } from 'templates/Templates';
import { Dhcp } from 'dhcp/Dhcp';
import IframeExample from 'iframe/IframeExample';

const App = () => {
  return (
    <Router>
      <div className="w-screen h-screen">
        <Routes>
          <Route element={<AuthLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/drive" element={<DriveContainer />} />
            <Route path="/ui" element={<UiHome />} />
            <Route path="/cluster" element={<Cluster />} />
            <Route path="/templates" element={<Templates />} />
            <Route path="/dhcp" element={<Dhcp />} />
            <Route path="/iframe" element={<IframeExample />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
};

export default App;
