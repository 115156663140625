import React from 'react';
import { useNavigate } from 'react-router-dom';
import SettingsNavbar from './SettingsNavbar';
import NavbarUserCard from './NavbarUserCard';
import { UnstyledButton, Group, Avatar, Text, rem, TextInput, Code, Badge } from '@mantine/core';
import {
  IconBulb,
  IconUser,
  IconCheckbox,
  IconSearch,
  IconPlus,
  IconHome,
  IconPlaystationTriangle,
} from '@tabler/icons-react';

const Navbar = (props: {}) => {
  const history = useNavigate();

  const links = [
    { icon: IconPlaystationTriangle, label: 'Drive', notifications: 4, path: '/drive' },
    { icon: IconUser, label: 'Design Ideas', path: '/ui' },
    { icon: IconPlus, label: 'Cluster', path: '/cluster' },
    { icon: IconPlus, label: 'Templates', path: '/templates' },
    { icon: IconUser, label: 'DHCP', path: '/dhcp' },
    { label: 'iframe', path: '/iframe' },
  ];

  const mainLinks = links.map(link => (
    <UnstyledButton key={link.label} className="flex flex-row align-center justify-between w-full">
      <div className="flex flex-row justify-around gap-2">
        {/* <link.icon size={20} stroke={1.5} /> */}
        <span onClick={() => history(link.path)}>{link.label}</span>
      </div>
      {link.notifications && (
        <Badge size="sm" variant="filled">
          {link.notifications}
        </Badge>
      )}
    </UnstyledButton>
  ));

  const radius = 8;

  return (
    <nav className="flex flex-col bg-slate-100 w-64 p-4 justify-between">
      <nav className="flex flex-col justify-around gap-2">
        <NavbarUserCard />

        <TextInput
          className="mb-4"
          placeholder="Search"
          size="xs"
          styles={{
            wrapper: {
              borderTopLeftRadius: radius,
              borderTopRightRadius: radius,
              borderBottomLeftRadius: radius,
              borderBottomRightRadius: radius,
            },
            section: { pointerEvents: 'none', marginRight: 5 },
            input: {
              borderTopLeftRadius: radius,
              borderTopRightRadius: radius,
              borderBottomLeftRadius: radius,
              borderBottomRightRadius: radius,
            },
          }}
          leftSection={<IconSearch size={14} />}
          rightSection={
            <Code>
              <Text size="xs" color="dimmed">
                ⌘K
              </Text>
            </Code>
          }
        />
        {mainLinks}
      </nav>

      <SettingsNavbar />
    </nav>
  );
};

export default Navbar;
