import React, { useEffect, useRef } from 'react';
import design from 'assets/tefas/tefas1.jpg';
import ImageGallery from 'react-image-gallery';

// https://github.com/xiaolin/react-image-gallery?tab=readme-ov-file

const TefasUi = (props: {}) => {
  const galleryRef = useRef();

  // useEffect(() => {
  //   galleryRef?.current && galleryRef.current.toggleFullScreen();
  // }, []);

  const images = [design].map(e => {
    return {
      original: e,
      thumbnail: e,
    };
  });

  return (
    <ImageGallery ref={galleryRef} items={images} lazyLoad={true} useBrowserFullscreen={false} />
  );
};

export default TefasUi;
