import React from 'react';
import DriveUi from 'ui/DriveUi';
import EvilayetUi from 'ui/EvilayetUi';
import TefasUi from 'ui/TefasUi';
import Ui from 'ui/Ui';

import { Tabs } from '@mantine/core';
import ElecricityUi from './ElectrictyUi';
import BillingUi from './BillingUi';
import WebSqlIde from './WebSqlIde';
import GithubUI from './GithubUI';

export const UiHome = (props: {}) => {
  const components = {
    drive: <DriveUi />,
    evilayet: <EvilayetUi />,
    tefas: <TefasUi />,
    ui: <Ui />,
    electricity: <ElecricityUi />,
    billing_ui: <BillingUi />,
    ['Web Sql IDE']: <WebSqlIde />,
    ['Github UI']: <GithubUI />,
  };
  const entries = Object.entries(components);

  // {entries.map(entry => <Tabs.Panel value={entry[0]}>{entry[1]}</Tabs.Panel>}
  return (
    <div className="w-full">
      <Tabs defaultValue="drive">
        <Tabs.List>
          {Object.keys(components).map(each => (
            <Tabs.Tab value={each}>{each}</Tabs.Tab>
          ))}
        </Tabs.List>
        {entries.map(([key, value]) => (
          <Tabs.Panel value={key}>{value}</Tabs.Panel>
        ))}
      </Tabs>
    </div>
  );
};
