import { DataTable } from 'mantine-datatable';
import React from 'react';

const WindowsClusterOverviewTable = (props: {}) => {
  return (
    <DataTable
      striped
      highlightOnHover
      records={[{ id: 1, name: 'Joe Biden', bornIn: 1942, party: 'Democratic' }]}
      idAccessor="id"
      scrollAreaProps={{ type: 'never' }}
      columns={[
        {
          accessor: 'name',
          title: 'Name',
        },
        {
          accessor: '_',
          title: 'Size',
        },
      ]}
      noRecordsText="No records found"
    />
  );
};

export default WindowsClusterOverviewTable;
