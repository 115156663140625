import React from 'react';
import { useNavigate } from 'react-router-dom';

const SettingsNavbar = (props: {}) => {
  const history = useNavigate();

  return (
    <nav className="flex">
      <ul>
        {['Settings', 'Support center'].map(e => {
          return (
            <li key={e} className="cursor-pointer" onClick={() => history(e.toLowerCase())}>
              {e}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default SettingsNavbar;
