import React, { useEffect, useState } from 'react';
import { Tabs } from '@mantine/core';
import axios from 'axios';
import { DriveTable } from './DriveTable';

export const DriveParentPanel = ({ label, parentId, isRoot }) => {
  const [data, setData] = useState();
  const d = [1, 2, 3].map(e => e.toString());

  useEffect(() => {
    const f = async () => {
      try {
        const { data } = await axios.get(`/drive/list/${parentId}`);
        if (data) {
          console.log(data);
          setData(data);
        }
      } catch (e) { }
    };
    f();
  }, []);

  return (
    <>
      {data && (
        <Tabs.Panel value={label} key={label}>
          <main className="pt-4">
            <Tabs key={label} defaultValue={data[0]?.id ?? ''} keepMounted={false}>
              {isRoot ? null : (
                <Tabs.List>
                  {data.map(e => {
                    return (
                      <Tabs.Tab key={e.name} value={e.id}>
                        {e.name}
                      </Tabs.Tab>
                    );
                  })}
                </Tabs.List>
              )}

              {data.map(e => {
                return <DriveTable key={e.id} parentId={e.id} value={e.id} />;
              })}
            </Tabs>
          </main>
        </Tabs.Panel>
      )}
    </>
  );
};
