import React from 'react';
import { Tabs, rem } from '@mantine/core';

export const DriveFolderList = ({ data }: any) => {
  return (
    <React.Fragment key={'dfolder'}>
      {data && (
        <Tabs.List>
          {data.map(e => {
            return <Tabs.Tab value={e.label}>{e.label}</Tabs.Tab>;
          })}
        </Tabs.List>
      )}
    </React.Fragment>
  );
};
