import { Button, Tabs } from '@mantine/core';
import { JsonToReactMantineDataTable } from 'json-to-react-mantine-datatable/JsonToReactMantineDataTable';
import React from 'react';
import jstemplates from './js-templates.json';
import javatemplates from './java-templates.json';
import reactemplates from './react-templates.json';
import gotemplates from './go-templates.json';

export const Templates = (props: {}) => {
  const customRenders = {
    Address: {
      render: (row: string) => (
        <Button
          component="a"
          fullWidth
          variant="default"
          target="_blank"
          href={row['Address']?.match(/\(([^()]*)\)/)?.[1] ?? row['Address']}
        >
          {row['Address']?.match(/\(([^()]*)\)/)?.[1] ?? row['Address']}
        </Button>
      ),
    },
  };

  const components = {
    JavaScript: (
      <JsonToReactMantineDataTable striped data={jstemplates} customRenders={customRenders} />
    ),
    Java: <JsonToReactMantineDataTable data={javatemplates} customRenders={customRenders} />,
    React: <JsonToReactMantineDataTable data={reactemplates} customRenders={customRenders} />,
    Go: <JsonToReactMantineDataTable data={gotemplates} customRenders={customRenders} />,
  };
  const entries = Object.entries(components);

  // {entries.map(entry => <Tabs.Panel value={entry[0]}>{entry[1]}</Tabs.Panel>}
  // return <JsonToReactMantineDataTable data={x} customRenders={customRenders} />;
  return (
    <div className="w-full">
      <Tabs defaultValue="JavaScript">
        <Tabs.List>
          {Object.keys(components).map(each => (
            <Tabs.Tab value={each}>{each}</Tabs.Tab>
          ))}
        </Tabs.List>
        <br />
        {entries.map(([key, value]) => (
          <Tabs.Panel value={key}>{value}</Tabs.Panel>
        ))}
      </Tabs>
    </div>
  );
};
