import React, { useEffect, useRef } from 'react';
import design from 'assets/sidebar-design.png';
import ImageGallery from 'react-image-gallery';

// https://github.com/xiaolin/react-image-gallery?tab=readme-ov-file

const Ui = (props: {}) => {
  const galleryRef = useRef();

  // useEffect(() => {
  //   galleryRef?.current && galleryRef.current.toggleFullScreen();
  // }, []);

  const images = [
    design,
    'https://pbs.twimg.com/media/GOozeafaIAEOiki?format=jpg&name=4096x4096',
    'https://pbs.twimg.com/media/GIUwbeWasAA57a_?format=jpg&name=4096x4096',
    'https://pbs.twimg.com/media/GI9GRFbacAATfrh?format=jpg&name=large',
    'https://pbs.twimg.com/media/GPF4Xp2WoAAmHdc?format=jpg&name=4096x4096',
    'https://pbs.twimg.com/media/GI-C9ngW4AAWcva?format=jpg&name=4096x4096',
    'https://pbs.twimg.com/media/GI8BkmSawAArOFL?format=jpg&name=4096x4096',
    'https://pbs.twimg.com/media/GI7KmuObsAA8CRf?format=jpg&name=4096x4096',
    'https://pbs.twimg.com/media/GGXiJWJWMAAfSe1?format=jpg&name=4096x4096',
    'https://pbs.twimg.com/media/GGNWuqNXkAAlnu5?format=jpg&name=4096x4096',
    'https://pbs.twimg.com/media/GI3u84ja4AA5d22?format=jpg&name=4096x4096',
    'https://pbs.twimg.com/media/GIsqB0-WgAI2AkX?format=jpg&name=large',
    'https://pbs.twimg.com/media/GMpQjzoa0AAxG_t?format=jpg&name=4096x4096',
    'https://pbs.twimg.com/media/GIn9NJ3WQAAfpNV?format=jpg&name=4096x4096',
    'https://pbs.twimg.com/media/GIj4DLgWcAE-Pr6?format=jpg&name=4096x4096',
    'https://pbs.twimg.com/media/GIiRl42boAAlq3m?format=jpg&name=4096x4096',
    'https://pbs.twimg.com/media/GIS00TfawAErYG4?format=jpg&name=4096x4096',
    'https://pbs.twimg.com/media/GHxJ86UWUAAC7jn?format=jpg&name=large',
    'https://pbs.twimg.com/media/GH0vr81XIAAeinc?format=jpg&name=4096x4096',
    'https://pbs.twimg.com/media/GHfU8MbbkAASDVl?format=jpg&name=4096x4096',
    'https://pbs.twimg.com/media/GHaokUsXsAAZOg5?format=jpg&name=4096x4096',
    'https://pbs.twimg.com/media/GF-wpR0XkAAOCRX?format=jpg&name=4096x4096',
    'https://pbs.twimg.com/media/GOg22_sXAAAeAU9?format=jpg&name=4096x4096',
    'https://pbs.twimg.com/media/GOP6dx1aUAAwPFM?format=jpg&name=4096x4096',
    'https://pbs.twimg.com/media/GLdAo9HaMAAXujd?format=jpg&name=4096x4096',
  ].map(e => {
    return {
      original: e,
      thumbnail: e,
    };
  });

  return (
    <div className="w-4/5 m-8">
      <ImageGallery ref={galleryRef} items={images} lazyLoad={true} useBrowserFullscreen={false} />
    </div>
  );
};

export default Ui;
