import React, { useEffect, useRef } from 'react';
import design from 'assets/tefas/tefas1.jpg';
import ImageGallery from 'react-image-gallery';

// https://github.com/xiaolin/react-image-gallery?tab=readme-ov-file

const BillingUi = (props: {}) => {
  const galleryRef = useRef();

  // useEffect(() => {
  //   galleryRef?.current && galleryRef.current.toggleFullScreen();
  // }, []);

  const images = ['https://pbs.twimg.com/media/GO-8Z9iWoAA-TY_?format=jpg&name=4096x4096'].map(
    e => {
      return {
        original: e,
        thumbnail: e,
      };
    },
  );

  return (
    <ImageGallery ref={galleryRef} items={images} lazyLoad={true} useBrowserFullscreen={false} />
  );
};

export default BillingUi;
