import React from 'react';

import { Tabs } from '@mantine/core';
import WindowsCluster from 'cluster/WindowsCluster';

const Cluster = (props: {}) => {
  const components = {
    'Windows (Overview)': <WindowsCluster />,
  };
  const keys = Object.keys(components);

  return (
    <div className="w-full">
      <Tabs defaultValue={keys[0]}>
        <Tabs.List>
          {keys.map(each => (
            <Tabs.Tab value={each}>{each}</Tabs.Tab>
          ))}
        </Tabs.List>
        {Object.entries(components).map(([key, value]) => (
          <Tabs.Panel value={key}>{value}</Tabs.Panel>
        ))}
      </Tabs>
    </div>
  );
};

export default Cluster;
