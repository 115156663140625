import React, { useEffect, useMemo, useState } from 'react';
import { DataTable, type DataTableSortStatus } from 'mantine-datatable';
import { Button, Text } from '@mantine/core';
import axios from 'axios';

export const Dhcp = (props: {}) => {
  const [clients, setClients] = useState([]);
  const instance = useMemo(() => {
    return axios.create({
      baseURL: 'http://192.168.0.250:5010',
    });
  }, []);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<Company>>({
    columnAccessor: 'name',
    direction: 'asc',
  });

  const sorts = useMemo(() => {
    return {
      'hostname.asc': (a, b) => {
        return a.hostname.localeCompare(b.hostname, undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      },
      'hostname.desc': (a, b) => {
        return b.hostname.localeCompare(a.hostname, undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      },
      'ip.asc': (a, b) => {
        const num1 = Number(
          a.ip
            .split('.')
            .map(num => `000${num}`.slice(-3))
            .join(''),
        );
        const num2 = Number(
          b.ip
            .split('.')
            .map(num => `000${num}`.slice(-3))
            .join(''),
        );
        return num2 - num1;
      },
      'ip.desc': (a, b) => {
        const num1 = Number(
          a.ip
            .split('.')
            .map(num => `000${num}`.slice(-3))
            .join(''),
        );
        const num2 = Number(
          b.ip
            .split('.')
            .map(num => `000${num}`.slice(-3))
            .join(''),
        );
        return num1 - num2;
      },
    };
  }, []);

  const loadTable = async (flag: boolean = false) => {
    try {
      let { data } = await instance.get(flag ? `/?load=true` : '/');

      if (data) {
        const sorted = data.sort((a, b) => {});

        setClients(sorted);
      }
    } catch (e) {}
  };

  useEffect(() => {
    loadTable();
  }, []);

  useEffect(() => {
    const { columnAccessor, direction } = sortStatus;
    console.log(`${columnAccessor}.${direction}`);

    setClients(clients.sort(sorts[`${columnAccessor}.${direction}`]));
  }, [sortStatus]);

  return (
    <div className="flex flex-col w-full gap-4">
      <div className="flex justify-between items-center">
        <Text>DHCP Clients</Text>
        <Button
          color="teal"
          onClick={() => {
            setClients([]);
            loadTable(true);
          }}
        >
          Refresh
        </Button>
      </div>
      <DataTable
        striped
        highlightOnHover
        records={clients}
        idAccessor="id"
        scrollAreaProps={{ type: 'never' }}
        columns={[
          {
            accessor: 'id',
            title: 'DHCP Id',
            width: '8%',
          },
          {
            accessor: 'ip',
            title: 'Addr',
            sortable: true,
          },
          {
            accessor: 'hostname',
            title: 'Hostname',
            sortable: true,
          },
          {
            accessor: 'mac_addr',
            title: 'Mac',
          },
          {
            accessor: 'lease_time',
            title: 'Lease Time',
          },
        ]}
        paginationActiveBackgroundColor="#40c057" // 👈 override default pagination active background color
        noRecordsText="No records found"
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
      />
    </div>
  );
};
