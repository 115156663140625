import React, { useEffect, useRef } from 'react';
import design from 'assets/bibliyomani/bibliyomani1.jpg';

import ImageGallery from 'react-image-gallery';

// https://github.com/xiaolin/react-image-gallery?tab=readme-ov-file

const DriveUi = (props: {}) => {
  const galleryRef = useRef();

  // useEffect(() => {
  //   galleryRef?.current && galleryRef.current.toggleFullScreen();
  // }, []);

  const images = [
    design,
    'https://pbs.twimg.com/media/GOnhF5za4AAyqa-?format=jpg&name=4096x4096',
    'https://pbs.twimg.com/media/GO6pl0gXMAAceVM?format=jpg&name=4096x4096',
  ].map(e => {
    return {
      original: e,
      thumbnail: e,
    };
  });

  return (
    <ImageGallery ref={galleryRef} items={images} lazyLoad={true} useBrowserFullscreen={false} />
  );
};

export default DriveUi;
