import { Tabs } from '@mantine/core';
import React from 'react';
import { DriveFolderList } from './DriveFolderList';
import { DriveParentPanel } from './DriveParentPanel';

function DriveContainer() {
  const data = [
    { parentId: '1nwKcyLp0ndLkM5y4tLZdaQPLndY3tq4V', label: '22M0208 (2024)' },
    { parentId: '1A8ElityCAfAmAgFTuM6kdWITGkXs76hj', label: 'ENES USTA MAIN (2024)' },
    { parentId: '1Gh4-mJFv-kfeTTyNwO7aRG0XjiOm3pRW', label: 'Sahis Sirketim' },
    // { parentId: '1X70iIii4phr1g7RD6uU0au-p7thYomAa', label: '22M0208 (Genel)' },
    {
      parentId: '1I2AZ1L_YbI23f3BXc-f84zEgudPtdbrD',
      label: '22M0208 Gelir Gider Tablolari',
      isRoot: true,
      isExcel: true,
    },
    // { parentId: '1868piCcb9cnuqqKuvi0Ugiiz-_d1kckL', label: 'SALIH KYK 24 AY', isRoot: true },
    // { parentId: '1ljiA-w2pOWQqZTHz0yuFw57NzWYrggl5', label: 'KDV ODEMELERI 48 AY', isRoot: true },
  ];

  return (
    <div className="w-full">
      <Tabs defaultValue={'22M0208 (2024)'} color="orange" variant="pills" keepMounted={false}>
        <DriveFolderList data={data} />

        {data.map(e => {
          return <DriveParentPanel {...e} />;
        })}
      </Tabs>
    </div>
  );
}

export default DriveContainer;
