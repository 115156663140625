import Navbar from 'navbar/Navbar';
import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from 'sidebar/Sidebar';

export default function MainRouteContainer() {
  return (
    <main className="flex flex-col h-screen">
      <div className="flex flex-1 overflow-hidden">
        <Navbar />
        <div className="flex flex-1 flex-col">
          {/** <div className="flex bg-gray-300 h-16 p-4">Header</div> */}
          <div className="flex flex-1 overflow-y-auto bg-slate-50 paragraph px-4 w-full pt-10">
            <Outlet />
          </div>
        </div>
      </div>
      {/**
      <div className="flex bg-gray-100">Footer</div> */}
    </main>
  );
}
